const COPYRIGHT = 'Classmethod, Inc.'

/**
 * 調剤ステータス
 */
const PRESCRIPTION_STATUS = {
  ALL: '全て',
  NEW: '未処理',
  DISPENSING: '調剤中',
  DISPENSED: '調剤完了',
  FOLLOWUP: '服薬フォロー中',
  COMPLETED: '対応完了',
  CANCELED: '取消',
  WARNING: '期限間近',
}

const PRESCRIPTION_COMPLETED_STATUS = {
  ALL: '全て',
  SEND_PRESCRIPTION: '処方せん送信のみ',
  MEDICATION_FOLLOW_UP: '服薬フォロー',
}

/**
 * 服薬フォローメッセージステータス
 */
const MEDICATION_FOLLOW_UP_MESSAGE_STATUS = {
  ALL: { label: '全て', filterName: 'all' },
  UNTREATED: { label: '未処理', filterName: 'untreated' },
  SEND_RESERVED: { label: '送信予約済み', filterName: 'sendReserved' },
  UNREAD: { label: '未読(患者)', filterName: 'unread' },
  READ: { label: '既読(患者)', filterName: 'read' },
}

const MEDICATION_FOLLOW_UP_ORDER_KEY_NAME = {
  ACCEPT_CODE: { label: '受付番号', sortName: 'acceptedCode' },
  ACCEPT_AT: { label: '受付時間', sortName: 'acceptedAt' },
  NAME: { label: 'ユーザー名', sortName: 'name' },
  IMAGE_COUNT: { label: '枚数', sortName: 'imageCount' },
  CHECK_LIST: { label: 'チェック項目', sortName: 'checkList' },
  MESSAGE_STATUS: { label: 'メッセージ', sortName: 'status' },
}

export {
  COPYRIGHT,
  PRESCRIPTION_STATUS,
  PRESCRIPTION_COMPLETED_STATUS,
  MEDICATION_FOLLOW_UP_MESSAGE_STATUS,
  MEDICATION_FOLLOW_UP_ORDER_KEY_NAME,
}
