import React, { useContext, useEffect, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { NavigationContext } from 'contexts'
import { Container, Drawer, Box, Typography } from 'components/atoms'
import { Banners } from 'components/molecules'
import { ConfirmModal, Portal } from 'components/organisms'
import { bannerHeight } from 'components/molecules/Banners'
import { useAppDispatch, useAppSelector } from 'libs/hooks'
import { RootState } from 'core/store'
import { clearError } from 'core/modules/error'

/** 通知の文言 */
const NOTIFICATION_TITLE = ''
const NOTIFICATION_BODY = '新しい処方せんが届きました。'
const NOTIFICATION_ICON = '/favicon.ico'

/**
 * Style
 */
const sidebarWidth = 260
const headerHeight = 64

const RootDiv = styled('div')({
  background: '#F5F5F5',
  display: 'grid',
  fallbacks: {
    display: '-ms-grid',
  },
  width: '100%',
  height: '100%',
  '-ms-grid-rows': '64px 1fr 64px',
  '-ms-grid-columns': '1fr',
})

const HeaderBarBox = styled(Box)(({ theme }) => ({
  '-ms-grid-row': 1,
  width: '100%',
  zIndex: theme.zIndex.drawer + 2,
  position: 'fixed',
}))

const BannerBox = styled(Box)(({ theme }) => ({
  height: 0,
  width: '100%',
  zIndex: theme.zIndex.drawer + 1,
  position: 'fixed',
  top: headerHeight,
}))

const ContentSpacesDiv = styled('div')<{ bannerCount: number }>(({ bannerCount }) => ({
  '-ms-grid-row': 2,
  width: '100%',
  height: '100%',
  display: 'flex',
  transitionDuration: '0.1s',
  paddingTop: `${headerHeight + bannerHeight * bannerCount}px`,
}))

const SidebarItemsBox = styled(Box)<{ bannerCount: number }>(({ bannerCount }) => ({
  width: sidebarWidth,
  height: '100%',
  transitionDuration: '0.1s',
  willChange: 'padding',
  paddingTop: `${headerHeight + bannerHeight * bannerCount}px`,
}))

const StyledMain = styled('main')<{ sidebarDisplayCondition: boolean }>(({ theme, sidebarDisplayCondition }) => ({
  height: '100%',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: -sidebarWidth,
  background: '#F5F5F5',
  ...(sidebarDisplayCondition && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}))

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: sidebarWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    boxShadow: theme.shadows[1],
  },
}))

/**
 * Interface
 */

export interface PageTemplateProps {
  headerBar: React.ReactNode
  sidebar: React.ReactNode
  children: React.ReactElement
  notificationCleanUpFunc: () => void
}

export default function PageTemplate(props: PageTemplateProps) {
  const { children, headerBar, sidebar, notificationCleanUpFunc } = props

  const dispatch = useAppDispatch()
  const { sidebarDisplayCondition } = useContext(NavigationContext)

  // 新着通知存在フラグ
  const hasNotification = useAppSelector(rootState => rootState.hasNotification.hasNotification)
  useEffect(() => {
    if (hasNotification) {
      new Notification(NOTIFICATION_TITLE, { body: NOTIFICATION_BODY, icon: NOTIFICATION_ICON })
      new Notification(NOTIFICATION_TITLE, { body: NOTIFICATION_BODY, icon: NOTIFICATION_ICON })
    } else {
      notificationCleanUpFunc()
    }
  }, [hasNotification])

  const hasNotCompatibleNotification = useAppSelector(
    rootState => rootState.hasNotCompatibleNotification.hasNotCompatibleNotification,
  )

  const hasNewMedicationFollowUpNotification = useAppSelector(
    rootState => rootState.HasNewMedicationFollowUpNotification.hasNewMedicationFollowUpNotification
  )

  const hasNewMedicationFollowUpMessage = useAppSelector(
    rootState => rootState.HasNewMedicationFollowUpMessageNotification.hasNewMedicationFollowUpMessageNotification
  )

  // エラー情報
  const error = useAppSelector((state: RootState) => state.error.error)
  const socketError = useAppSelector((state: RootState) => state.socketError.error)

  const bannerCount = useMemo(
    () => [hasNotification, !!socketError, hasNotCompatibleNotification].filter(flg => flg).length,
    [hasNotification, socketError, hasNotCompatibleNotification],
  )

  return (
    <RootDiv>
      <HeaderBarBox display="block" displayPrint="none">
        {headerBar && headerBar}
      </HeaderBarBox>
      <BannerBox displayPrint="none">
        <Banners
          hasNotification={hasNotification}
          error={socketError}
          hasNotCompatible={hasNotCompatibleNotification}
          hasNewMedicationFollowUp={hasNewMedicationFollowUpNotification}
          hasNewMedicationFollowUpMessage={hasNewMedicationFollowUpMessage}
        />
      </BannerBox>
      <ContentSpacesDiv bannerCount={bannerCount}>
        <Box display="block" displayPrint="none">
          <StyledDrawer variant="persistent" anchor="left" open={sidebarDisplayCondition}>
            <SidebarItemsBox bannerCount={bannerCount}>{sidebar && sidebar}</SidebarItemsBox>
          </StyledDrawer>
        </Box>
        <StyledMain sidebarDisplayCondition={sidebarDisplayCondition}>
          <StyledContainer>{children}</StyledContainer>
          <Typography textAlign={'right'} displayPrint="none">
            Sound created by <a href="https://otologic.jp/">OtoLogic</a>.
          </Typography>
        </StyledMain>
      </ContentSpacesDiv>
      <Portal targetId={'modal'}>
        <ConfirmModal
          isOpened={!!error}
          description={error ? error?.message : ''}
          onClickDone={() => {
            dispatch(clearError())
          }}
        />
      </Portal>
    </RootDiv>
  )
}
