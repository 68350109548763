import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector, useAppNavigate } from 'libs/hooks'
import { hooks } from 'libs'
import { Usecases } from 'core'
import MedicationFollowUpPrescriptionsPage from 'components/screens/MedicationFollowUpPrescriptionsPage'
import { RootState } from 'core/store'
import {
  filter,
  sortPrescriptions,
  isDescOrder,
  FILTER_INITIAL_STATUS_ALL,
  ORDER_INITIAL_KEY_ACCEPT_CODE,
} from 'libs/hooks/useMedicationFollowUp'
import {
  clearOriginOriginFollowUpPrescriptions,
  clearFollowUpPrescriptions,
  setFollowUpPrescriptions,
} from 'core/modules/followUpPrescriptions'
import { PrescriptionsHeaderItem } from 'libs/hooks/useMedicationFollowUp'
import { clearPrescriptionDetail } from 'core/modules/prescriptionDetail'
import { clearPatients } from 'core/modules/patients'
import { clearMessages } from 'core/modules/messages'
import { setHasNewMedicationFollowUpNotification } from 'core/modules/hasNewMedicationFollowUpNotification'
import { setHasNewMedicationFollowUpMessageNotification } from 'core/modules/hasNewMedicationFollowUpMessageNotification'

export default function MedicationFollowUpPrescriptionsPageContainer() {
  const shopId = useAppSelector((state: RootState) => state.currentShop.shopId)
  const dispatch = useAppDispatch()
  const token = hooks.useAuth().idToken
  const { navigate } = useAppNavigate()

  useEffect(() => {
    if (!shopId) return
    dispatch(clearOriginOriginFollowUpPrescriptions())
    dispatch(Usecases.getMedicationFollowUpPrescriptions.sendGetMedicationFollowUpPrescriptions(token, shopId))
    dispatch(setHasNewMedicationFollowUpNotification(false))
    dispatch(setHasNewMedicationFollowUpMessageNotification(false))
  }, [shopId])

  const prescriptions = useAppSelector((state: RootState) => state.followUpPrescriptions.originPrescriptions)
  const filterDataList = useAppSelector((state: RootState) => state.followUpPrescriptions.prescriptions)
  /* eslint-disable-next-line */
  const [sortOrder, setSortOrderKey] = useState<any>({ sortKey: ORDER_INITIAL_KEY_ACCEPT_CODE, order: 'desc' })
  const [filterType, setFilterType] = useState<string>(FILTER_INITIAL_STATUS_ALL)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false)

  useEffect(() => {
    if (!isFirstRender) return
    dispatch(setFollowUpPrescriptions(sortPrescriptions(filterDataList, sortOrder)))
  }, [sortOrder])

  useEffect(() => {
    setIsFirstRender(true)
  }, [])

  const handlePrescriptionsUpdate = () => {
    if (!shopId) return
    dispatch(clearOriginOriginFollowUpPrescriptions())
    dispatch(Usecases.getMedicationFollowUpPrescriptions.sendGetMedicationFollowUpPrescriptions(token, shopId))
    dispatch(setHasNewMedicationFollowUpNotification(false))
    dispatch(setHasNewMedicationFollowUpMessageNotification(false))
  }

  return (
    <MedicationFollowUpPrescriptionsPage
      prescriptions={filterDataList}
      filter={filterType}
      sortOrder={sortOrder}
      handlePrescriptionsUpdate={() => {
        handlePrescriptionsUpdate()
      }}
      handleFilterChange={(filterName: string) => {
        setFilterType(filterName)
        dispatch(clearFollowUpPrescriptions())
        dispatch(setFollowUpPrescriptions(filter(prescriptions, filterName)))
      }}
      handleOrderChange={(sort: PrescriptionsHeaderItem) => {
        const changeSortOrder = isDescOrder(sortOrder.order) ? 'asc' : 'desc'
        setSortOrderKey({ sortKey: sort.sortKey, order: changeSortOrder })
      }}
      onClick={(prescriptionId: string) => {
        dispatch(clearPrescriptionDetail())
        dispatch(clearPatients())
        dispatch(clearMessages())
        navigate(`/${shopId}/main/prescriptions/medication-follow-up/${prescriptionId}`)
      }}
    />
  )
}
