import React, { useRef, useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'libs/hooks'
import { styled } from '@mui/material/styles'
import MessagesViewer from '../MessageViewer'
import {
  Box,
  Typography,
  TextField,
  CommonButton,
  DatePicker,
  LocalizationProvider,
  AdapterDayjs,
} from 'components/atoms'
import dayjs, { Dayjs } from 'dayjs'
import { SectionPanel } from 'components/molecules'
import { MAX_MESSAGE_LENGTH, RESERVED_SEND_MESSAGE_MAX_DAY } from 'constants/value'
import { RootState } from 'core/store'
import { Usecases } from 'core'

const Content = styled(Box)(({ theme }) => ({
  height: `calc(100% - ${theme.typography.pxToRem(54)})`,
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderTopWidth: 1,
  paddingLeft: 16,
  paddingRight: 16,
  marginBottom: 16,
}))

const SectionHead = styled(Typography)({
  fontWeight: 'normal',
})

const MessageTextField = styled(TextField)({
  marginTop: 8,
  width: '100%',
})

const MessageSendBox = styled(Box)({
  width: '100%',
  marginTop: 8,
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledSendButton = styled(CommonButton)(({ theme }) => ({
  height: 42,
  background: '#2196F3',
  boxShadow: theme.shadows[1],
  lineHeight: theme.typography.pxToRem(20),
}))

const StyledSendReserveButton = styled(StyledSendButton)({
  marginLeft: 16,
})

const StyledFixedPhrase = styled(TextField)({
  width: 132,
  '& .MuiInputBase-input': {
    color: '#0B79D0',
    height: 36,
    padding: 8,
    fontSize: 14,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#0B79D0',
    },
  },
})

const ReservedMessageSendBox = styled(Box)({
  marginTop: 8,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

interface Props {
  token: string | undefined
  userName: string | undefined
  isCompleted: boolean
  isCanceled: boolean
  shopId: string | null
  onSend: (content: string, reservedAt: string | undefined) => void
  onCancel: (id: number) => void
  getOldMessages: (id: string, sendAt: string) => void
}

export default function MessagesSection(props: Props) {
  const date = dayjs()
  const CAN_RESERVED_SEND_MESSAGE_FROM_DATE = date.add(1, 'day')
  const CAN_RESERVED_SEND_MESSAGE_TO_DATE = date.add(RESERVED_SEND_MESSAGE_MAX_DAY, 'day')
  const [inputText, setInputText] = useState('')
  const [reservedDate, setReservedDate] = useState<Dayjs | null>(CAN_RESERVED_SEND_MESSAGE_FROM_DATE)
  const messageTemplates = useAppSelector((state: RootState) => state.MessageTemplates.messageTemplates)
  const dispatch = useAppDispatch()
  const isPostMessage = useRef(false)
  const [isReservedMessge, setIsReservedMessge] = useState(false)

  useEffect(() => {
    if (!props.shopId) return
    dispatch(Usecases.getMessageTemplates.sendGetMessageTemplates(props.token, props.shopId))
  }, [props.shopId])

  const fillMessage = () => {
    if (inputText.length > 0 && inputText.trim() !== '') {
      return true
    } else {
      return false
    }
  }

  const sendMessage = (isReserved: boolean = false) => {
    if (!fillMessage()) return
    const reservedAt = isReserved ? reservedDate?.format('YYYY-MM-DD') : undefined
    props.onSend(inputText, reservedAt)
    setInputText('')
    isPostMessage.current = true
  }

  const isDisabled = () => {
    return props.isCompleted || props.isCanceled || isReservedMessge
  }

  return (
    <SectionPanel header={<SectionHead variant="h6">メッセージ</SectionHead>}>
      <Content>
        <MessagesViewer
          token={props.token}
          userName={props.userName}
          shopId={props.shopId}
          isPostMessage={isPostMessage.current}
          onCancel={messageId => props.onCancel(messageId)}
          getOldMessages={(id, sendAt) => props.getOldMessages(id, sendAt)}
          onMessageListChanged={() => (isPostMessage.current = false)}
          onReservedMessage={flg => setIsReservedMessge(flg)}
        />
        <MessageTextField
          disabled={isDisabled()}
          variant="outlined"
          multiline
          InputProps={{ disableUnderline: true }}
          rows={2}
          placeholder="メッセージを入力してください"
          value={inputText}
          onChange={event => {
            if (event.target.value.length > MAX_MESSAGE_LENGTH) return
            setInputText(event.target.value)
          }}
        />
        <MessageSendBox>
          {messageTemplates && (
            <StyledFixedPhrase
              disabled={isDisabled()}
              select
              label=""
              placeholder="定型文を選択"
              value={messageTemplates}
              onChange={event => {
                setInputText(inputText + event.target.value)
              }}
              SelectProps={{ native: true }}>
              {messageTemplates.map(option => (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              ))}
            </StyledFixedPhrase>
          )}
          <StyledSendButton
            disabled={isDisabled()}
            type={'primary'}
            label={'送信'}
            click={() => {
              sendMessage()
            }}
          />
        </MessageSendBox>
        <ReservedMessageSendBox>
          <LocalizationProvider adapterLocale={'ja'} dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={isDisabled()}
              label="送信予定日"
              value={reservedDate}
              onChange={event => setReservedDate(event)}
              format="YYYY/MM/DD"
              minDate={CAN_RESERVED_SEND_MESSAGE_FROM_DATE}
              maxDate={CAN_RESERVED_SEND_MESSAGE_TO_DATE}
            />
          </LocalizationProvider>
          <StyledSendReserveButton
            disabled={isDisabled()}
            type={'primary'}
            label={'送信予約'}
            click={() => {
              sendMessage(true)
            }}
          />
        </ReservedMessageSendBox>
      </Content>
    </SectionPanel>
  )
}
