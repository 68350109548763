import React from 'react'
import { styled } from '@mui/material/styles'
import { Domain } from 'core'
import { hooks } from 'libs'
import { PrescriptionListSection } from 'components/organisms'
import { Box, Typography } from 'components/atoms'
import { LoadingPanel, PageHeader } from 'components/molecules'

const StyledBox = styled(Box)(({ theme }) => ({
  height: `calc(100% - ${theme.typography.pxToRem(106)})`,
  paddingBottom: theme.spacing(2),
}))

/**
 * interface
 */

interface InProcessPrescriptionsPageProps {
  shopId: string | null
  prescriptions?: Array<Prescription>
  handleFilterChange?: (filter: string) => void
  handleOrderChange: (sortKey: string) => void
  handlePrescriptionsUpdate: () => void
  sortOrder: SortOrder
  filter: string
}

type Prescription = Domain.Prescription.Prescription
type SortOrder = Domain.Prescription.SortOrder

export default function InProcessPrescriptionsPage(props: InProcessPrescriptionsPageProps) {
  const { navigate } = hooks.useAppNavigate()

  return (
    <StyledBox>
      <PageHeader>
        <Typography variant="h1" component="h2">
          処理中の処方せん
        </Typography>
      </PageHeader>
      <StyledBox>
        {props.prescriptions != null ? (
          <PrescriptionListSection
            prescriptions={props.prescriptions}
            onClick={(prescriptionId: string) =>
              navigate(`/${props.shopId}/main/prescriptions/in-process/${prescriptionId}`)
            }
            filter={props.filter}
            sortOrder={props.sortOrder}
            handleFilterChange={props.handleFilterChange}
            handleOrderChange={props.handleOrderChange}
            handlePrescriptionsUpdate={props.handlePrescriptionsUpdate}
          />
        ) : (
          <LoadingPanel />
        )}
      </StyledBox>
    </StyledBox>
  )
}
