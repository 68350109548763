import { useMemo } from 'react'
import { Domain } from 'core'
import { PRESCRIPTION_STATUS, PRESCRIPTION_COMPLETED_STATUS } from 'constants/text'
import { useAppDispatch, useAppSelector } from '.'
import { RootState } from 'core/store'
import { setPrescriptionDisplayConditions } from 'core/modules/prescriptionDisplayConditions'
import { PrescriptionDisplayConditions } from 'core/domain/prescription'

type Prescription = Domain.Prescription.Prescription
type SortOrder = Domain.Prescription.SortOrder

const filterKeyList = Domain.Prescription.filterKeyList
const doneFilterKeyList = Domain.Prescription.doneFilterKeyList

export interface StatusFilter {
  label: string
  filter: string
}

export interface PrescriptionsHeaderItem {
  label: string
  sortKey: string
  compare: (a: Prescription, b: Prescription) => number
  reverseCompare: (a: Prescription, b: Prescription) => number
}

/** 処方せんのフィルター項目 */
export const STATUS_FILTERS: Array<StatusFilter> = [
  {
    label: PRESCRIPTION_STATUS.ALL,
    filter: filterKeyList[0],
  },
  {
    label: PRESCRIPTION_STATUS.NEW,
    filter: filterKeyList[1],
  },
  {
    label: PRESCRIPTION_STATUS.DISPENSING,
    filter: filterKeyList[2],
  },
  {
    label: PRESCRIPTION_STATUS.DISPENSED,
    filter: filterKeyList[3],
  },
  {
    label: PRESCRIPTION_STATUS.WARNING,
    filter: filterKeyList[4],
  },
]

export const DONE_STATUS_FILTERS: Array<StatusFilter> = [
  {
    label: PRESCRIPTION_COMPLETED_STATUS.ALL,
    filter: doneFilterKeyList[0],
  },
  {
    label: PRESCRIPTION_COMPLETED_STATUS.SEND_PRESCRIPTION,
    filter: doneFilterKeyList[1],
  },
  {
    label: PRESCRIPTION_COMPLETED_STATUS.MEDICATION_FOLLOW_UP,
    filter: doneFilterKeyList[2],
  },
]

/** ベースソート処理 */
const compareBase = (a: string | number, b: string | number) => {
  let comparison = 0
  if (a > b) {
    comparison = 1
  } else if (a < b) {
    comparison = -1
  }
  return comparison
}

/** リバースソート処理 */
const reverseCompareBase = (a: string | number, b: string | number) => {
  let comparison = 0
  if (a > b) {
    comparison = -1
  } else if (a < b) {
    comparison = 1
  }
  return comparison
}

type compareFunc = (a: Prescription, b: Prescription) => number

/** デファルトのソート（未読順 + サーバから取得した順） */
const compareDefault: compareFunc = (a, b) => compareBase(Number(a.isRead), Number(b.isRead))

/** 受付番号のソート */
const compareAcceptedCode: compareFunc = (a, b) => compareBase(Number(a.acceptedCode), Number(b.acceptedCode))
/** 受付番号のリバースソート */
const reverseCompareAcceptedCode: compareFunc = (a, b) =>
  reverseCompareBase(Number(a.acceptedCode), Number(b.acceptedCode))

/** 受付時間のソート */
const compareAcceptedAt: compareFunc = (a, b) => compareBase(a.acceptedAt, b.acceptedAt)
/** 受付時間のリバースソート */
const reverseCompareAcceptedAt: compareFunc = (a, b) => reverseCompareBase(a.acceptedAt, b.acceptedAt)

/** 名前のソート */
const compareName: compareFunc = (a, b) => compareBase(a.name, b.name)
/** 名前のリバースソート */
const reverseCompareName: compareFunc = (a, b) => reverseCompareBase(a.name, b.name)

/** 処方せん画像枚数のソート */
const compareImageCount: compareFunc = (a, b) => compareBase(a.imageCount, b.imageCount)
/** 処方せん画像枚数のリバースソート */
const reverseCompareImageCount: compareFunc = (a, b) => reverseCompareBase(a.imageCount, b.imageCount)

/** チェック項目のソート */
const compareCheckList: compareFunc = (a, b) => {
  const checkListA = [a.isGenericMedicine, a.hasPrescriptionNote, a.isPregnancy, a.isBreastFeeding, !!a.allergy].filter(
    check => check,
  ).length
  const checkListB = [b.isGenericMedicine, b.hasPrescriptionNote, b.isPregnancy, b.isBreastFeeding, !!b.allergy].filter(
    check => check,
  ).length

  return compareBase(checkListA, checkListB)
}
/** チェック項目のリバースソート */
const reverseCompareCheckList: compareFunc = (a, b) => {
  const checkListA = [a.isGenericMedicine, a.hasPrescriptionNote, a.isPregnancy, a.isBreastFeeding, !!a.allergy].filter(
    check => check,
  ).length
  const checkListB = [b.isGenericMedicine, b.hasPrescriptionNote, b.isPregnancy, b.isBreastFeeding, !!b.allergy].filter(
    check => check,
  ).length

  return reverseCompareBase(checkListA, checkListB)
}

/** 処方せんステータスのソート */
const compareStatus: compareFunc = (a, b) => {
  const status = ['new', 'dispensing', 'warning', 'dispensed', 'completed', 'canceled']
  const statusA = status.indexOf(a.status)
  const statusB = status.indexOf(b.status)

  return compareBase(statusA, statusB)
}
/** 処方せんステータスのリバースソート */
const reverseCompareStatus: compareFunc = (a, b) => {
  const status = ['new', 'dispensing', 'warning', 'dispensed', 'completed', 'canceled']
  const statusA = status.indexOf(a.status)
  const statusB = status.indexOf(b.status)

  return reverseCompareBase(statusA, statusB)
}

const createSortOrder = (sortKey: string, currentSortOrder: SortOrder) => {
  if (Domain.Prescription.isSortKey(sortKey)) {
    return {
      sortKey: sortKey,
      order: currentSortOrder.sortKey === sortKey && currentSortOrder.order === 'asc' ? 'desc' : 'asc',
    } as SortOrder
  }

  return null
}

/** 処方せん一覧の表示項目 */
export const PRESCRIPTIONS_HEADER_ITEMS: Array<PrescriptionsHeaderItem> = [
  {
    label: '受付番号',
    sortKey: 'acceptedCode',
    compare: compareAcceptedCode,
    reverseCompare: reverseCompareAcceptedCode,
  },
  {
    label: '受付時間',
    sortKey: 'acceptedAt',
    compare: compareAcceptedAt,
    reverseCompare: reverseCompareAcceptedAt,
  },
  {
    label: 'ユーザー名',
    sortKey: 'name',
    compare: compareName,
    reverseCompare: reverseCompareName,
  },
  {
    label: '枚数',
    sortKey: 'imageCount',
    compare: compareImageCount,
    reverseCompare: reverseCompareImageCount,
  },
  {
    label: 'チェック項目',
    sortKey: 'checkList',
    compare: compareCheckList,
    reverseCompare: reverseCompareCheckList,
  },
  {
    label: 'ステータス',
    sortKey: 'status',
    compare: compareStatus,
    reverseCompare: reverseCompareStatus,
  },
]

export const usePrescriptions = (): {
  filteredInProcessPrescriptions?: Prescription[]
  filteredDonePrescriptions?: Prescription[]
  setFilterForInProcess: (filter: string) => void
  setFilterForDone: (filter: string) => void
  setSortOrderForInProcess: (newSortKey: string) => void
  setSortOrderForDone: (newSortKey: string) => void
  displayConditions: PrescriptionDisplayConditions
} => {
  const displayConditions = useAppSelector(
    (state: RootState) => state.prescriptionDisplayConditions.prescriptionDisplayConditions,
  )

  // ----- 処理中の処方せん -----
  const inProcessPrescriptions = useAppSelector((state: RootState) => state.inProcessPrescriptions.prescriptions)

  const filteredInProcessPrescriptions = useMemo(() => {
    if (inProcessPrescriptions == null) return
    const { sortOrderForInProcess, filterForInProcess } = displayConditions

    let prescriptions = []

    if (filterForInProcess === STATUS_FILTERS[0].filter) {
      prescriptions = [...inProcessPrescriptions]
    } else {
      prescriptions =
        filterForInProcess === 'warning'
          ? inProcessPrescriptions.filter((prescription: Prescription) => prescription.isExpireSoon)
          : inProcessPrescriptions.filter((prescription: Prescription) => prescription.status === filterForInProcess)
    }

    if (sortOrderForInProcess.sortKey === 'default') {
      prescriptions.sort(compareDefault)
    } else {
      if (sortOrderForInProcess.order === 'desc') {
        const reverseCompare = PRESCRIPTIONS_HEADER_ITEMS.find(
          item => sortOrderForInProcess.sortKey === item.sortKey,
        )?.reverseCompare
        if (reverseCompare) prescriptions.sort(reverseCompare)
      } else {
        const compare = PRESCRIPTIONS_HEADER_ITEMS.find(item => sortOrderForInProcess.sortKey === item.sortKey)?.compare
        if (compare) prescriptions.sort(compare)
      }
    }

    return prescriptions
  }, [inProcessPrescriptions, displayConditions.sortOrderForInProcess, displayConditions.filterForInProcess])
  // ------------------------

  // ----- 完了済みの処方せん -----
  const donePrescriptions = useAppSelector((state: RootState) => state.donePrescriptions.prescription)

  const filteredDonePrescriptions = useMemo(() => {
    if (donePrescriptions == null) return
    const { sortOrderForDone, filterForDone } = displayConditions
    let prescriptions: Prescription[] = []

    if (filterForDone === DONE_STATUS_FILTERS[0].filter) {
      prescriptions = [...donePrescriptions]
    } else {
      donePrescriptions.map(data => {
        if (filterForDone === DONE_STATUS_FILTERS[1].filter && data.imageCount > 0) prescriptions.push(data)
        if (filterForDone === DONE_STATUS_FILTERS[2].filter && data.imageCount === 0) prescriptions.push(data)
      })
    }

    if (sortOrderForDone.sortKey === 'default') {
      prescriptions.sort(compareDefault)
    } else {
      if (sortOrderForDone.order === 'desc') {
        const reverseCompare = PRESCRIPTIONS_HEADER_ITEMS.find(
          item => sortOrderForDone.sortKey === item.sortKey,
        )?.reverseCompare
        if (reverseCompare) prescriptions.sort(reverseCompare)
      } else {
        const compare = PRESCRIPTIONS_HEADER_ITEMS.find(item => sortOrderForDone.sortKey === item.sortKey)?.compare
        if (compare) prescriptions.sort(compare)
      }
    }

    return prescriptions
  }, [donePrescriptions, displayConditions.filterForDone, displayConditions.sortOrderForDone])
  // ------------------------

  const dispatch = useAppDispatch()

  // 処理中の処方せん一覧のフィルタ設定
  const setFilterForInProcess = (filter: string) => {
    if (Domain.Prescription.isFilterKey(filter)) {
      const conditions = {
        ...displayConditions,
        filterForInProcess: filter,
      }
      dispatch(setPrescriptionDisplayConditions(conditions))
    }
  }

  // 処理中の処方せん一覧のソート設定
  const setSortOrderForInProcess = (newSortKey: string) => {
    const newSortOrder: SortOrder | null = createSortOrder(newSortKey, displayConditions.sortOrderForInProcess)
    if (newSortOrder) {
      const conditions = {
        ...displayConditions,
        sortOrderForInProcess: newSortOrder,
      }

      dispatch(setPrescriptionDisplayConditions(conditions))
    }
  }

  // 完了した処方せん一覧のフィルタ設定
  const setFilterForDone = (filter: string) => {
    if (Domain.Prescription.isDoneFilterKey(filter)) {
      const conditions = {
        ...displayConditions,
        filterForDone: filter,
      }
      dispatch(setPrescriptionDisplayConditions(conditions))
    }
  }

  // 完了した処方せん一覧のソート設定
  const setSortOrderForDone = (newSortKey: string) => {
    const newSortOrder: SortOrder | null = createSortOrder(newSortKey, displayConditions.sortOrderForDone)
    if (newSortOrder) {
      const conditions = {
        ...displayConditions,
        sortOrderForDone: newSortOrder,
      }

      dispatch(setPrescriptionDisplayConditions(conditions))
    }
  }

  return {
    filteredInProcessPrescriptions,
    filteredDonePrescriptions,
    setFilterForInProcess,
    setSortOrderForInProcess,
    setFilterForDone,
    setSortOrderForDone,
    displayConditions,
  }
}
