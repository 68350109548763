import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'libs/hooks'
import { hooks } from 'libs'
import { Usecases } from 'core'
import MedicationFollowUpPrescriptionMessages from 'components/screens/MedicationFollowUpPrescriptionMessages'
import { RootState } from 'core/store'
import { Domain } from 'core'
import { MESSAGES_GET_PER_PAGE } from 'constants/value'
import { clearMessages } from 'core/modules/messages'
import { setError } from 'core/modules/error'

type GetMessagesPayload = Domain.MedicationFollowUp.GetMessagesPayload

export default function MedicationFollowUpMessagesPageContainer() {
  const shopId = useAppSelector((state: RootState) => state.currentShop.shopId)
  const { prescriptionId = '' } = useParams<{ prescriptionId: string }>()
  const token = hooks.useAuth().idToken
  const dispatch = useAppDispatch()
  const prescription = useAppSelector((state: RootState) => state.prescriptionDetail.prescription)
  const messageList = useAppSelector((state: RootState) => state.messages.messages.messageList)

  useEffect(() => {
    if (!shopId) return
    dispatch(Usecases.getPrescriptionDetail.sendGetPrescriptionDetail(token, shopId, prescriptionId))
    dispatch(Usecases.readPrescription.sendPutPrescriptionRead(token, shopId, prescriptionId))
  }, [shopId])

  useEffect(() => {
    if (!prescription) return
    dispatch(clearMessages())
    const payload: GetMessagesPayload = {
      patientLineId: prescription.patientLineId,
      perPage: MESSAGES_GET_PER_PAGE,
      id: '',
      sendAt: '',
    }
    dispatch(Usecases.getMessages.sendGetInitialMessages(token, shopId, payload))
  }, [prescription?.id])

  useEffect(() => {
    if (!prescription?.patientLineId) return
    dispatch(Usecases.setMessagesRead.sendMessgesRead(token, shopId, prescription.patientLineId))
  }, [prescription?.patientLineId])

  useEffect(() => {
    if (messageList.length === 0) return
    const lastMessage = messageList[messageList.length - 1]
    if (lastMessage && lastMessage.errorMessage && lastMessage.errorMessage !== '') {
      dispatch(setError(new Error(lastMessage.errorMessage)))
    }
  }, [messageList.length])

  return (
    <MedicationFollowUpPrescriptionMessages
      token={token}
      shopId={shopId}
      prescription={prescription}
      changeStatus={(status: string) => {
        if (!shopId || !prescription) return
        dispatch(
          Usecases.setPrescriptionStatus.sendPutPrescriptionStatus(token, shopId, prescription.id.toString(), status),
        )
      }}
    />
  )
}
