import { Dispatch } from 'redux'
import * as Api from 'libs/api'
import { Domain } from 'core'
import { setInProcessPrescriptions } from 'core/modules/inProcessPrescriptions'
import { setHasNotification } from 'core/modules/hasNotification'

type Prescription = Domain.Prescription.Prescription

type PrescriptionsData = { prescriptions: Array<Prescription> }

function didCallGetInProcessPrescriptionsApi(dispatch: Dispatch, data: PrescriptionsData | Error) {
  if (data instanceof Error) return
  dispatch(setInProcessPrescriptions(data.prescriptions))
}

export function sendGetInProcessPrescriptions(idToken: string | undefined, shopId: string) {
  return async (dispatch: Dispatch) => {
    // 処理中の処方せんの取得時に新着処方せんの存在フラグを落とす
    dispatch(setHasNotification(false))
    // Send GET /mc/shops/{shopId}/prescriptions/in-process
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/${shopId}/prescriptions/in-process`,
      idToken,
      null,
      dispatch,
      didCallGetInProcessPrescriptionsApi,
    )
  }
}
