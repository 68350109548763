import React, { useState } from 'react'
import { useAppDispatch } from 'libs/hooks'
import { Usecases } from 'core'
import { styled } from '@mui/material/styles'
import { hooks } from 'libs'
import { Domain } from 'core'
import { PrescriptionInfoSection, ConfirmModal, Portal, MessagesSection } from 'components/organisms'
import { Box, CommonButton, Typography } from 'components/atoms'
import icoArrowBack from 'assets/images/ico_arrow_back.svg'
import { MESSAGES_GET_PER_PAGE } from 'constants/value'

const Root = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

const StyledCommonButton = styled(CommonButton)(({ theme }) => ({
  height: 42,
  background: '#2196F3',
  boxShadow: theme.shadows[1],
  lineHeight: theme.typography.pxToRem(20),
  color: '#FFFFFF',
}))

const StatusCheckBox = styled(Box)({
  paddingLeft: 16,
  paddingRight: 16,
  marginTop: 16,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 76,
  borderRadius: 4,
  backgroundColor: '#FFFFFF',
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderTopWidth: 1,
})

/**
 * Interface
 */
interface PrescriptionDetailPageProps {
  token: string | undefined
  shopId: string | null
  prescription: Prescription | null
  changeStatus: (status: string) => void
}

type Prescription = Domain.Prescription.Prescription
type SendMessagesPayload = Domain.MedicationFollowUp.SendMessagesPayload
type GetMessagesPayload = Domain.MedicationFollowUp.GetMessagesPayload

export default function MedicationFollowUpPrescriptionMessages(props: PrescriptionDetailPageProps) {
  const { token, shopId } = props
  const dispatch = useAppDispatch()
  const { prescription, changeStatus } = props
  const [isOpened, setIsOpened] = useState(false)
  const { navigatePop } = hooks.useAppNavigate()

  const closeModal = () => {
    setIsOpened(false)
  }

  const sendMessage = (content: string, reservedAt: string | undefined) => {
    if (!prescription || !prescription.patientLineId) return
    const payload: SendMessagesPayload = {
      patientLineId: prescription.patientLineId,
      content: content,
      reservedAt: reservedAt,
    }
    dispatch(Usecases.setMessages.sendPostMessages(token, shopId, payload))
  }

  const cancelReservedMessage = (id: number) => {
    dispatch(Usecases.putMessages.sendPutMessages(token, shopId, id))
  }

  const getOldMessages = (id: string, sendAt: string) => {
    if (!prescription) return
    const payload: GetMessagesPayload = {
      patientLineId: prescription.patientLineId,
      perPage: MESSAGES_GET_PER_PAGE,
      id: id,
      sendAt: sendAt,
    }
    dispatch(Usecases.getMessages.sendGetMessages(token, shopId, payload))
  }

  return (
    <Box>
      <Root display="block" displayPrint="none">
        <Box fontSize={34} marginTop={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          メッセージ
        </Box>
        <Box marginTop={4}>
          <PrescriptionInfoSection prescription={prescription} />
        </Box>
        <Box marginTop={2}>
          <MessagesSection
            token={token}
            userName={prescription?.name}
            isCompleted={prescription?.status === 'completed'}
            isCanceled={prescription?.status === 'canceled'}
            shopId={shopId}
            onSend={sendMessage}
            onCancel={cancelReservedMessage}
            getOldMessages={getOldMessages}
          />
        </Box>
        <Box marginTop={2}>
          <StatusCheckBox sx={{ boxShadow: 1 }}>
            <Typography>服薬フォローを完了する</Typography>
            <StyledCommonButton
              disabled={prescription?.status == 'completed' || prescription?.status == 'canceled'}
              type={'primary'}
              label={'服薬フォローを完了する'}
              click={() => {
                setIsOpened(true)
              }}
            />
          </StatusCheckBox>
        </Box>
        <Box width="100%" display="flex" justifyContent="flex-end" marginTop={2}>
          <StyledCommonButton type={'primary'} label={'戻る'} icon={icoArrowBack} click={navigatePop} />
        </Box>
      </Root>
      <Box display="none" displayPrint="block">
        <PrescriptionInfoSection prescription={prescription} />
      </Box>
      <Portal targetId={'modal'}>
        <ConfirmModal
          isOpened={isOpened}
          title={'服薬フォローを完了する'}
          description={
            '服薬フォローを完了します。\n服薬フォロー完了後メッセージを送ることはできません。\n(メッセージの履歴は残ります)'
          }
          hasClose={false}
          isLarge={false}
          doneButtonLabel={'OK'}
          cancelButtonLabel={'キャンセル'}
          onClickDone={() => {
            changeStatus('completed')
            closeModal()
          }}
          onClickCancel={closeModal}
          onClickClose={closeModal}
        />
      </Portal>
    </Box>
  )
}
