import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { Domain } from 'core'
import { SectionPanel } from 'components/molecules'
import {
  Typography,
  TableContainer,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Box,
  StatusChip,
  CheckListIcons,
  CommonButton,
} from 'components/atoms'
import icoArrowRight from 'assets/images/ico_arrow_right.svg'
import icoLoop from 'assets/images/ico_loop.svg'
import {
  STATUS_FILTERS,
  DONE_STATUS_FILTERS,
  StatusFilter,
  PRESCRIPTIONS_HEADER_ITEMS,
  PrescriptionsHeaderItem,
} from 'libs/hooks/usePrescriptions'
import { date } from 'libs'

const RootBox = styled(Box)({
  height: '100%',
})

const SectionHeadTypography = styled(Typography)({
  fontWeight: 'normal',
})

const StyledBox = styled(Box)<{ filter: boolean }>(({ theme, filter }) => ({
  height: `calc(100% - ${theme.typography.pxToRem(54)})`,
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderTopWidth: 1,
  ...(filter && {
    height: `calc(100% - ${theme.typography.pxToRem(54)} - ${theme.typography.pxToRem(64)})`,
  }),
}))

const FilterTabs = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
  '& .MuiTabs-indicator': {
    backgroundColor: '#F50057',
  },
}))

const StyledTab = styled(Tab)<{ isSameIndex: boolean }>(({ isSameIndex }) => ({
  width: 124,
  minWidth: 124,
  ...(isSameIndex && {
    color: '#F50057',
  }),
}))

const StyledTableContainer = styled(TableContainer)({
  height: '100%',
})

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& th': {
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
}))

const StyledTableCell = styled(TableCell)({
  '& span': {
    width: '100%',
  },
  '& svg': {
    marginLeft: 'auto',
    opacity: '0.54 !important',
  },
})

const StyledTableRow = styled(TableRow)<{ isRead: boolean; isExpireSoon: boolean }>(({ isRead, isExpireSoon }) => ({
  '&:hover': {
    backgroundColor: '#BBDEFB !important',
  },
  ...(!isRead && {
    backgroundColor: '#2196F314',
  }),
  ...(isExpireSoon && {
    backgroundColor: '#E91E6314',
  }),
}))

const IconImg = styled('img')({
  width: 10,
  height: 10,
})

/**
 * Interface
 */

export interface PrescriptionListSectionProps {
  prescriptions: Array<Prescription>
  onClick: (prescriptionId: string) => void
  handleFilterChange?: (filter: string) => void
  handleOrderChange: (sortKey: string) => void
  handlePrescriptionsUpdate: () => void
  sortOrder: SortOrder
  filter?: string
  page?: string
}

type Prescription = Domain.Prescription.Prescription
type SortOrder = Domain.Prescription.SortOrder

export default function PrescriptionListSection(props: PrescriptionListSectionProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterTabChange = (event: any, tabIndex: number) => {
    if (props.handleFilterChange) props.handleFilterChange(STATUS_FILTERS[tabIndex].filter)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDoneFilterTabChange = (event: any, tabIndex: number) => {
    if (props.handleFilterChange) props.handleFilterChange(DONE_STATUS_FILTERS[tabIndex].filter)
  }

  const tabIndex = useMemo(() => {
    return STATUS_FILTERS.findIndex(status => status.filter === props.filter)
  }, [props.filter])

  const doneTabIndex = useMemo(() => {
    return DONE_STATUS_FILTERS.findIndex(status => status.filter === props.filter)
  }, [props.filter])

  return (
    <RootBox>
      <SectionPanel
        header={
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <SectionHeadTypography variant="h6">処方せん一覧</SectionHeadTypography>
            <CommonButton
              type={'primary'}
              label={'更新'}
              icon={icoLoop}
              click={() => {
                props.handlePrescriptionsUpdate()
              }}
            />
          </Box>
        }>
        <StyledBox paddingLeft={2} paddingRight={2} paddingBottom={2} filter={!!props.filter}>
          {props.page !== 'done' && props.handleFilterChange && (
            <Box>
              <FilterTabs value={tabIndex} onChange={handleFilterTabChange} aria-label="filter tabs">
                {STATUS_FILTERS.map((filter: StatusFilter, index: number) => {
                  return <StyledTab isSameIndex={index === tabIndex} label={filter.label} key={index} />
                })}
              </FilterTabs>
            </Box>
          )}
          {props.page === 'done' && props.handleFilterChange && (
            <Box>
              <FilterTabs value={doneTabIndex} onChange={handleDoneFilterTabChange} aria-label="filter tabs">
                {DONE_STATUS_FILTERS.map((filter: StatusFilter, index: number) => {
                  return <StyledTab isSameIndex={index === doneTabIndex} label={filter.label} key={index} />
                })}
              </FilterTabs>
            </Box>
          )}
          <StyledTableContainer>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  {PRESCRIPTIONS_HEADER_ITEMS.map((item: PrescriptionsHeaderItem, index: number) => {
                    return (
                      <StyledTableCell key={index}>
                        <TableSortLabel
                          active={true}
                          direction={props.sortOrder.sortKey === item.sortKey ? props.sortOrder.order : 'desc'}
                          onClick={() => props.handleOrderChange(item.sortKey)}>
                          {item.label}
                        </TableSortLabel>
                      </StyledTableCell>
                    )
                  })}
                  <TableCell></TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {props.prescriptions.map((prescription: Prescription, index: number) => {
                  return (
                    <StyledTableRow
                      isRead={prescription.isRead}
                      isExpireSoon={prescription.isExpireSoon && prescription.isRead}
                      hover
                      key={index}
                      onClick={() => props.onClick(prescription.id.toString())}>
                      <TableCell scope="row">{prescription.acceptedCode}</TableCell>
                      <TableCell>{date.formatDate(prescription.acceptedAt)}</TableCell>
                      <TableCell>{prescription.name}</TableCell>
                      <TableCell>{prescription.imageCount}</TableCell>
                      <TableCell>
                        <CheckListIcons prescription={prescription} />
                      </TableCell>
                      <TableCell>
                        <StatusChip status={prescription.status} />
                      </TableCell>
                      <TableCell>
                        <IconImg src={icoArrowRight} alt={`${index}`} />
                      </TableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </StyledBox>
      </SectionPanel>
    </RootBox>
  )
}
